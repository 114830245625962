<!--  -->
<template>
  <div>
    <el-dialog :title="dialog.title" :visible.sync="dialog.dialogVisible" @close="handleClose" width="40%">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="SKU名称">
          <el-input v-model="form.name" style="width:40%" placeholder="请输入SKU名称"></el-input>
        </el-form-item>
        <el-form-item label="SKU值">
          <div>
            <el-input v-model="childValue" style="width:40%" placeholder="请输入SKU值" @keyup.enter.native="handleAddChildValue"></el-input>
            <el-button @click="handleAddChildValue" type="primary" style="margin-left:10px">增加</el-button>
          </div>
          <div class="spec_list">
            <el-tag style="margin-right:10px" v-for="(tag, tagIdx) in form.child" :key="tagIdx" closable @close="handleTagClose(tag, tagIdx)" effect="plain" type="info">
              {{ tag.name }}
            </el-tag>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialog.dialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="handleConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { specValue } from '@/api/SpecController'

export default {
  name: '',
  components: {},
  props: {
    dialog: {
      type: Object,
      default: function(value) {
        return {
          title: '提示',
          dialogVisible: false
        }
      }
    },
    specInfo: Object
  },
  data() {
    return {
      form: this.specInfo,
      childValue: ''
    }
  },
  watch: {
    specInfo(v) {
      // console.log(v)
      this.form = this.specInfo
    }
  },
  methods: {
    handleAddChildValue() {
      if (!this.childValue) return
      this.form.child.push({
        name: this.childValue,
        valueId: ''
      })
      this.childValue = ''
    },
    handleConfirm() {
      if (!this.form.name) return this.$message.warning('请填写SKU名称')
      if (!this.form.child.length) return this.$message.warning('请最少填写一个SKU值')
      this.$emit('confirm', this.form)
    },
    handleTagClose(tag, index) {
      this.form.child.splice(index, 1)
    },
    handleClose() {}
  },
  mounted() {}
}
</script>
<style lang="less" scoped>
/* @import url(); 引入css类 */
</style>
