<template>
  <div class="container">
    <TitleBarBaseVue />
    <div class="handle_bar">
      <el-row :gutter="20" type="flex" justify="space-between">
        <el-col :xs="7" :sm="3" :md="2" :lg="2" :xl="2" class="flex">
          <el-button type="primary" @click="handleEdit(1)">添加</el-button>
        </el-col>
        <el-col :xs="24" :sm="10" :md="8" :lg="8" :xl="5" class="flex">
          <el-input v-model="params.keyWords" @change="fecthList" clearable placeholder="请输入名称关键词"> </el-input>
          <el-button class="margin-left-xs" type="primary" @click="fecthList">搜索</el-button>
        </el-col>
      </el-row>
    </div>
    <el-table :data.sync="tableList" border>
      <el-table-column label-class-name="header" prop="specId" label="编号" width="100" align="center"></el-table-column>
      <el-table-column prop="name" label="SKU名称" align="center" width="200"></el-table-column>
      <el-table-column prop="level" label="SKU值" align="center">
        <template slot-scope="scope">
          {{ scope.row.child | filterChildValue }}
        </template>
      </el-table-column>
      <el-table-column label="状态" width="100" align="center">
        <template slot-scope="scope">
          <el-button @click="handleStatus(scope.row)" v-if="scope.row.delFlag == 1" size="mini" type="warning">隐藏</el-button>
          <el-button @click="handleStatus(scope.row)" v-else size="mini" type="primary">显示</el-button>
        </template>
      </el-table-column>
      <el-table-column prop label="操作" align="center" width="100">
        <template slot-scope="scope">
          <el-button size="mini" type="danger" @click="handleEdit(2, scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <SkuEditDialog :dialog="skuEditDialog" :specInfo="specInfo" @confirm="confirmSkuEditDialog" />
  </div>
</template>

<script>
import TitleBarBaseVue from '@/components/TitleBar/TitleBarBase.vue'
import { specPage, specUpdate, specStatus, specValue } from '@/api/SpecController'
import SkuEditDialog from './components/SkuEditDialog.vue'
export default {
  components: {
    TitleBarBaseVue,
    SkuEditDialog
  },
  data() {
    return {
      params: {
        keyWords: ''
      },
      tableList: [],
      skuEditDialog: {
        title: '编辑sku',
        dialogVisible: false,
        type: 1
      },
      specInfo: {
        name: '',
        specId: '',
        child: []
      }
    }
  },

  mounted() {
    this.fecthList()
  },

  methods: {
    async handleStatus(row) {
      let [err, res] = await specStatus({ specId: row.specId })
      if (err) {
        console.log(err)
        return this.$message.error(err.msg || '操作失败')
      }
      console.log(res)
      row.delFlag = res.data.delFlag
    },
    async confirmSkuEditDialog(data) {
      let [err, res] = await specUpdate(data)
      if (err) {
        console.log(err)
        return this.$message.error(err.msg || '操作失败')
      }
      console.log(res)
      this.skuEditDialog.dialogVisible = false
      this.fecthList()
    },
    // 编辑
    handleEdit(type, row) {
      this.skuEditDialog.title = type == 1 ? '添加sku' : '编辑sku'
      this.skuEditDialog.type = type
      this.skuEditDialog.dialogVisible = true
      if (type == 1) {
        this.specInfo = {
          name: '',
          specId: '',
          child: []
        }
      } else {
        this.specInfo = this.$cloneDeep(row)
      }
    },
    async fecthList() {
      let [err, res] = await specPage(this.params)
      if (err) {
        console.log(err)
        return this.$message.error(err.msg || '获取列表失败')
      }
      console.log(res)
      this.tableList = res.data
    }
  },
  filters: {
    filterChildValue(arr) {
      let r = arr.reduce((prev, curr) => {
        return prev + curr.name + ','
      }, '')
      return r.slice(0, r.length - 1)
    }
  }
}
</script>
